<template>
  <div class="maskbg" v-if="showload">
	<div class="box">
		<div class="img">
			<img src="../assets/image/loading.png" />
		</div>
		<p>加载中...</p>
	</div>
  	
  </div>
</template>

<script>
export default {
    props:{
        showload:Boolean
    }
}
</script>

<style scoped>
   .maskbg {
   	position: fixed;
   	width: 100%;
   	height: 100%;
   	left: 0;
   	top: 0;
   	right: 0;
   	bottom: 0;
	z-index:99999;
   	margin: auto;
   	background: #fff;
   	display: flex;
   	align-items: center;
   	justify-content: center;
   }
   .maskbg .box{
	   display: flex;
	   align-items: center;
	   flex-direction: column;
   }
   
   .maskbg .box p{
   	   color: #3E3A39 
	}
   .maskbg .img img {
   	width: 50px;
   	height: 50px;
   	animation: rotateAnimation 2s linear infinite;
   }
   @keyframes rotateAnimation {
   	0% {
   		transform: rotate(0deg);
   	}
   	/* from关键帧，初始状态为不旋转 */
   	100% {
   		transform: rotate(360deg);
   	}
   	/* to关键帧，最后状态为完全旋转一周 */
   }

</style>