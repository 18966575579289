<template>
  <div class="wechat">
	  企微登录
    <div id="wechat"></div>
	<!-- <a :href="redirect_url">
		{{redirect_url}}
	</a> -->
  </div>
</template>
<script>


export default {
  name: 'weChatLogin', 
  data () {
    return {
		redirect_url:'',
		qwopenid:''
    }
  },
  mounted(){
	  this.qwopenid = this.$route.query.qwopenid
	  let redirect_uri = encodeURIComponent("https://gzh.dxerp.net/customer/callback/wxworkauthcode?qwopenid=" + this.qwopenid)
	  this.redirect_url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.qwopenid + '&redirect_uri=' + redirect_uri + '&response_type=code&scope=snsapi_base&state=a123456#wechat_redirect' 
	// this.createWechatLogin()  
	 window.location.href =  this.redirect_url
	
  },
  methods: {
	  
  },

 
}
</script>
<style scoped lang="scss">
.wechat{
  display: inline-flex;
  justify-content: center;
  width: 320px;
  height: 380px;
  background-color: #fff ;
}
.enable-alert{
  display: flex;
  align-items: center;
}
</style>
