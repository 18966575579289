import httpapi from '../../utils/httpapi';
const marketApi = {
	//分类-行业推荐
	hangyehot(param, funsucc) {
		httpapi.post('/customer/market/market/cls/hangyehot', {}).then(res => {
			console.log(res, 'hangyehot.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//分类-行业
	hangyelist(param, funsucc) {
		httpapi.post('/customer/market/market/cls/hangyelist', {}).then(res => {
			console.log(res, 'hangyelist.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//分类-热销推荐
	salehot(param, funsucc) {
		httpapi.post('/customer/market/market/cls/salehot', {}).then(res => {
			console.log(res, 'salehot.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//分类-热销列表
	salelist(param, funsucc) {
		httpapi.post('/customer/market/market/cls/salelist', {}).then(res => {
			console.log(res, 'salelist.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//分类-风格推荐
	stylehot(param, funsucc) {
		httpapi.post('/customer/market/market/cls/stylehot', {}).then(res => {
			console.log(res, 'stylehot.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//分类-风格
	stylelist(param, funsucc) {
		httpapi.post('/customer/market/market/cls/stylelist', {}).then(res => {
			console.log(res, 'delete.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//首页推荐免费模版
	listbyfree(param, funsucc) {
		httpapi.post('/customer/market/market/default/listbyfree', {}).then(res => {
			console.log(res, 'listbyfree.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//首页推荐付费模版
	listbypay(param, funsucc) {
		httpapi.post('/customer/market/market/default/listbypay', {}).then(res => {
			console.log(res, 'listbypay.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//首页推荐付费模版
	detail(param, funsucc) {
		httpapi.post('/customer/market/market/detail', param).then(res => {
			console.log(res, 'detail.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//详情页精品模版
	listbyjingpin(param, funsucc) {
		httpapi.post('/customer/market/market/detail/listbyjingpin', {}).then(res => {
			console.log(res, 'listbyjingpin.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//系统模版市场查询
	search(param, funsucc) {
		httpapi.post('/customer/market/search', param).then(res => {
			console.log(res, 'search.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
}
export default marketApi