import { render, staticRenderFns } from "./wxgzh.vue?vue&type=template&id=9c18f7b6&scoped=true&"
import script from "./wxgzh.vue?vue&type=script&lang=js&"
export * from "./wxgzh.vue?vue&type=script&lang=js&"
import style0 from "./wxgzh.vue?vue&type=style&index=0&id=9c18f7b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c18f7b6",
  null
  
)

export default component.exports