<template>
	<div class="qwcontent">
		
		<div v-if="mpid != undefined && mpid.length > 0">
			<a :href="qwurl" target="_blank">
				<div class="banner" style="width: 100%;">
					<img src="../../../assets/image/qwautoindex/icon_qwbanner1.png" />
				</div>
			</a>
			
		</div>


		<div class="banner" style="width: 100%;" v-else>
			<img src="../../../assets/image/qwautoindex/icon_qwbanner2.png" @click="funinfoqw"/>
			
		</div>
		<div class="mchname">
			<div>{{unitName}}</div>
			<div>编号：{{unitno}}</div>
		</div>
		<div class="banner">
			<img src="../../../assets/image/qwautoindex/icon_qwimg1.png" />
		</div>
		<div class="banner">
			<img src="../../../assets/image/qwautoindex/icon_qwimg2.png" />
		</div>
		<div class="banner">
			<img src="../../../assets/image/qwautoindex/icon_qwimg3.png" />
		</div>
		<el-dialog title="企微注册引导" :visible.sync="priceVisible" width="600px" @click='priceVisible=false'>
			<div v-if="showedit == 'sel'">
				<el-form>
					<el-form-item label="开放平台">
						第三方平台
					</el-form-item>
					<el-form-item label="企业微信名称" prop="mpname">
						<el-input size="medium" v-model="editForm.mpname" auto-complete="off" placeholder="请输入企业微信名称"
							class="ipt"></el-input>
					</el-form-item>
					<div class="applist">
						<div class="aitem" v-for="(item,index) in openlist" :class="item.appid == editForm.open_appid ? 'selopen' : 'normal'" @click.stop="funselopenlist(item)" :key="index">
							<img :src="item.logo" alt="" class="applogo">
							<div class="right">
								<div class="mc">{{item.appname}}</div>
								<div class="memo">{{item.memo}}</div>
							</div>
						</div>
					</div>
					<div class="dialog-footer">
						<el-button style="width: 150px;margin-right: 20px;" size="medium"
							@click='showedit=false'>取消</el-button>
						<el-button style="width: 150px;margin-left: 20px;" size="medium" type="primary"
							:loading="loading" class="title" @click="addisv('editForm')">绑定
						</el-button>
					</div>
				</el-form>
			</div>
			<div class="regcontent" v-if="showedit == 'reg'">
				<div>

					<div class="main">
						<div class="box">
							<div class="item">
								<div class="img">
									<img src="../../../assets/image/qiyewx.png" />
								</div>
								<div class="btn">

									<el-button size="small" type="primary"
										@click="funApplyqw('bind')">已有企业微信，立即绑定</el-button>
								</div>
								<div class="memo">
									<p>为保证所有功能的正常使用</p>
									<p>授权时请把所有权限统一授权给大新</p>
								</div>
							</div>
							<div class="item">
								<div class="img">
									<img src="../../../assets/image/qiyewx.png" />
								</div>
								<div class="btn">
									<a target="_blank" href="https://work.weixin.qq.com/">
										<el-button size="small" @click="funApplyqw('add')">没有企业微信，立即申请</el-button>
									</a>
								</div>
								<div class="memo">
									<p>请在微信公众平台申请新的企业微信</p>
									<p>成功后再到大新进行绑定</p>
								</div>
							</div>
						</div>

					</div>



				</div>

			</div>
			<div class="regcontent" v-if="showedit == 'auth'">
				<div>

					<div class="main">
						<div class="box">
							<div class="item">
								<div class="img">
									<img src="../../../assets/image/qiyewx.png" />
								</div>
								<div class="btn">
									<a target="_blank" :href="authurl">
										<el-button size="small" type="primary">立即授权企业微信</el-button>
									</a>
								</div>
								<div class="memo">
									<p>{{mpid}}</p>
									<p>{{editForm.mpname}}</p>
									<p style="color:#ff0000">{{error}}</p>
								</div>
							</div>

						</div>

					</div>



				</div>

			</div>
			
		</el-dialog>

	</div>
</template>

<script>
	import callApi from '../../../api/cus/account.js'
	import accountApi from '../../../api/cus/account.js'
	import {
		setloginuser,
		getloginuser
	} from '../../../utils/util'
	export default {
		data() {
			return {
				logintoken:'',
				priceVisible: false,
				mpid: '',
				loading: false,
				showedit: 'sel', //sel reg auth授权
				authurl: '',
				auth_state: '',
				error: '',
				qwurl: '',
				editForm: {
					"mpid": "",
					"unitno": 0,
					"aeskey": "",
					"appID": "",
					"appSecret": "",
					"corpid": "",
					"mpname": "",
					"bookSecret": "",
					"contactSecret": "",
					"open_appid":""
				},
				openlist:[{
						appid:'wxbccc9ed8f3b74a88',
						appname:"大新客户关系系统",
						memo:'大新企微客户关系系统',
						logo:require("../../../assets/image/icon_applogo.png")
				},
				{
						appid:'wx53bb3dbb829782cf',
						appname:"大新酒吧点单系统",
						memo:'大新会员扫码点单',
						logo:require("../../../assets/image/icon_applogo2.png")
				}],
				unitName:'',
				unitno:''
			}
		},
		mounted() {
		    this.editForm.open_appid = ''
			this.editForm.mpname = ''
			this.totype = this.$route.query.totype
			this.showedit = 'sel'
			let u = getloginuser()
				
			if(u != undefined){
				this.unitName = u.unitName
				this.unitno = u.unitno
			}else{
				this.$router.push("/");
			}
			if(this.totype != undefined){
				
			}else{
				this.logintoken = this.$route.query.token
				console.log(this.unitName,'unitName');
				this.mpid = ''
				this.getregedqw()
			}
			
		},
		methods: {
			getregedqw() {
				accountApi.getwxworkmpid(res => {
					console.log(res, 'getregedqw.res');
					if (res.code == 200) {
						this.mpid = res.data.mpid
						let qwurl = '/customer/home/mp/weixinqiye/alls/index?mpid=' + res.data.mpid
						
						this.qwurl = '/customer/callback/autologin?token=' + this.logintoken + "&redirect_url=" + encodeURIComponent(qwurl)
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			funinfoqw() {
				this.editForm.open_appid = ''
				this.editForm.mpname = ''
				this.$confirm('请在企业微信安装此应用', '提示', {
					confirmButtonText: '确定',
					showCancelButton:false,
					type: 'warning'
				}).then(() => {
					
				}).catch(() => {
				
				});
				// this.priceVisible = true
			},
			funApplyqw() {
				this.showedit = 'reg'

			},
			addisv() {
				if(this.editForm.mpname.length <= 0){
					this.$message({
						message:"请输入企业微信名称",
						type:'error'
					})
					return
				}
				if(this.editForm.open_appid.length <= 0){
					this.$message({
						message:"请选择应用",
						type:'error'
					})
					return
				}
				
				let param = {
					"mpname": this.editForm.mpname,
					"open_appid":this.editForm.open_appid
				}
				console.log(param, 'addqywx.param');
				accountApi.addqywxisv(param, res => {
					console.log(res, 'addqywx.res');
					if (res.code == 200) {
						this.showedit = 'auth'
						this.mpid = res.data.mpid
						this.getauthurl()
						this.$message({
							type: 'success',
							message: res.msg
						})

					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			getauthurl() {
				console.log(this.mpid, 'getauthurl.mpid');
				accountApi.qywxauthurl(this.mpid, res => {
					console.log(res, 'qywxauthurl.res');
					if (res.code == 200) {
						this.authurl = res.data.auth_url
						this.auth_state = res.data.auth_state
					} else {
						this.error = "授权获取失败," + res.msg
					}
				})
			},
			funselopenlist(item){
				this.editForm.open_appid = item.appid
			}
		}
	}
</script>

<style scoped>
	.qwcontent {
		position: relative;
	}

	.qwcontent .banner {
		margin-top: 38px;
		width: 1;
		height: auto;
		margin: 0 auto;
		width: 1518px;
	}

	.qwcontent .banner img {
		width: 100%;
		height: 100%;
	}



	.main {
		display: flex;
		flex-direction: column;
	}

	.main .title {
		margin: 20px 0;
		font-size: 16px;
		font-weight: 800;
		text-align: center;
	}

	.main .box {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.main .box .item {
		margin: 0 10px;
		width: 45%;
		padding: 20px 0;
		display: flex;
		border-radius: 5px;
		align-items: center;
		flex-direction: column;
		border: 1px solid #f0f0f0;
	}

	.main .box .memo {
		color: #C8C8CD;
		text-align: center;
	}

	.main .box .item .img {
		width: 54px;
		height: 54px;
	}

	.main .box .item .btn {
		margin: 20px 0;
	}

	.main .box .item .img img {
		width: 100%;
		height: 100%;
	}

	.main .footer {
		border-radius: 5px;
		border: 1px solid #f0f0f0;
		margin: 20px 35px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.main .footer .item {
		padding: 25px;
		display: flex;
		align-items: center;
	}

	.main .footer .op {
		color: #6C6B71;
		margin-left: 5px;
	}

	.main .footer .item .img {
		width: 30px;
		height: 30px;
	}

	.main .footer .item .img img {
		width: 100%;
		height: 100%;
	}

	.dialog-footer {
		margin-top: 50px;
		display: flex;
		justify-content: center;
	}
	.mchname{
		position: absolute;
		top: 32px;
		right:20px;
		font-size: 18px;
		color:#221266;
		font-weight: 700;
	}
	.ipt{
		width: 250px;
	}
	.applist{
		display: flex;
		flex-direction: row;
	}
	.applist .aitem{
		display: flex;
		align-items: center;
		padding: 5px 10px;
		border-radius: 5px;
		
		margin-right: 10px;
		cursor:pointer;
	}
	.applist .aitem:hover{
	border: 1px solid #00aaff;
	}
	
	.applist .aitem .applogo{
		width: 40px;
		height: 40px;
		margin-right: 10px;
		
	}
	.applist .aitem .mc{
		font-weight: 600;
	}
	.applist .aitem .memo{
		font-size: 10px;
		color: #a09fa7;
	}
	.normal{
		border: 1px solid #ececec;
	}
	.selopen{
		border: 1px solid #00aaff;
	}
	@media screen and (max-width: 414px){
		.qwcontent .banner {
			margin-top: 38px;
			width: 1;
			height: auto;
			margin: 0 auto;
			width: 100%;
		}
		.ipt{
			width: 150px;
		}
	}
</style>