<template>
	<div class="bottombox">
		<div class="list boder" style="align-items: center;">
				<div>大新软件旗下品牌：</div>
				<div class="item" v-for="(item,index) in sellist" :key="index">{{item.mc}}</div>
		</div>
		<div class="list">
			<a hidefocus="true" href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">
				<i class="fkw-footer-beian"></i>
				<span>蜀ICP备14021816号</span>
			</a>
			<span class="fkw-footer-spec">|</span>
			<span>Copyright</span>
			<font style="font-family: 微软雅黑;">©</font>
			<span>2010-2024 成都久智大新科技有限公司</span>
			<a hidefocus="true" href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">蜀ICP备14021816号</a>
		</div>


	</div>
</template>

<script> 

	export default {
		props: {

		},
		data() {
			return {
				sellist: [{
						mc: '公众平台',
						patht: ''
					},
					{
						mc: '小酒窝元宇宙',
						patht: ''
					},
					{
						mc: '大新酒吧管理系统',
						patht: ''
					},
					{
						mc: '大新支付系统',
						patht: ''
					},
					{
						mc: '公众号管理系统',
						patht: ''
					},
					{
						mc: '小酒窝后台管理系统',
						patht: ''
					},
					{
						mc: '大新前台点单系统',
						patht: ''
					},

					{
						mc: '服务员点单系统',
						patht: ''
					},
					{
						mc: '会员点单系统',
						patht: ''
					},
					{
						mc: '代理商系统',
						patht: ''
					}
				]
			}
		}
	}
</script>

<style scoped>
	@import '../assets/css/fkwComm.min.css';
.bottombox{
	height: 109px;
	width: 100%;
	color: #606060;
	font-size: 9px;
	margin-top: 20px;
}
.bottombox .list{
	display: flex;
	justify-content: center;
	
	height: 54px;
	line-height: 54px;
}
.bottombox .list .item{
	margin-right: 16px;
}
.bottombox .boder{
	border-bottom: 1px solid #E2E2E2;
}
</style>