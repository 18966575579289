<template>
	<div class="content">
		<div class="title">
			公众平台授权
		</div>
		<div class="fixbox">
			<div class="top">
				<div class="imgbox">
					<img src="../../../assets/image/market/icon_success.png" alt="">
				</div>
				<div class="msg">{{authmsg}}</div>
				<div class="btn" @click="funback" v-if="showbtn">返回首页</div>
				<div class="btn" @click="queryzt" v-else>返回首页</div>
			</div>
			<div class="bottom">
				<div class="bitem">大新软件企业服务</div>
				<div class="bitem">餐饮 休闲娱乐 数据系统 支付收银</div>
				<div class="bitem">大新软件是一家专业从事企业信息化管理的高科技企业。</div>
			</div>
		</div>
	</div>
</template>

<script>
	import callApi from '../../../api/cus/account.js'
	export default {
		data() {
			return {
				authmsg: "",
				mpid:'',
				showbtn:true
			}
		},
		mounted() {
			this.queryzt()
		},
		methods: {
			queryzt() {
				let url = window.location.href;
				let params = url.split('?')[1];
				let paramsObj = {};
				if (params) {
					let paramsArr = params.split('&');
					for (let i = 0; i < paramsArr.length; i++) {
						let param = paramsArr[i].split('=');
						paramsObj[param[0]] = param[1];
					}
				}
				let mpid = paramsObj['id'];
				console.log(mpid, 'mpid');
				this.mpid = mpid
				let auth_code = paramsObj['auth_code'];
				console.log(auth_code, 'auth_code');


				callApi.weixinauthcall(mpid, auth_code, res => {
					console.log(res, 'allauthcall')
					if (res.code == 200) {
						this.authmsg = res.msg
						this.showbtn = true
					} else {
							this.showbtn = false
					}
				})
			},
			funback(){
				this.$router.push({
					path:"/customer/home/mp/weixingzh/alls/index?mpid=" + this.mpid,
				})
			}
		}
	}
</script>

<style scoped>
	.content{
		width: 100%;
		height: 100vh;
		overflow: auto;
		background-color: #F6F8F9;
	}
	.content .title{
		font-size:18px;
		font-weight:700;
		width:100%;
		text-align: center;
		line-height: 150px;
	}
	.content .fixbox{
		width: 350px;
		border:1px solid #E1E1E1;
		margin: 0 auto;
		background-color: #ffffff;
	}
	.content .fixbox .top{
		height: 200px;
	}
	.content .fixbox .top .imgbox{
		width:64px;
		height: 64px;
		margin: 32px auto;
	}
	.content .fixbox .top .imgbox img{
		width:100%;
		height:100%;

	}
	.content .fixbox .top .msg{
		width: 100%;
		text-align: center;
		line-height: 50px;
	}
	.content .fixbox .top .btn{
		width: 120px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 30px;
		background-color: #52C41A;
		color:#ffffff;
		margin: 0 auto;
	}
	.content .fixbox .bottom{
		background-color: #F3F3F3;
		padding: 32px 40px;
	}
	.content .fixbox .bottom .bitem{
		margin-bottom: 10px;
	}
</style>