<template>
</template>

<script>
	import callApi from '../../../api/cus/account.js'
	import {
		setloginuser,getloginuser
	} from '../../../utils/util'
	export default {
		data() {
			return {

			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let url = window.location.href;
				let params = url.split('?')[1];
				let paramsObj = {};
				if (params) {
					let paramsArr = params.split('&');
					for (let i = 0; i < paramsArr.length; i++) {
						let param = paramsArr[i].split('=');
						paramsObj[param[0]] = param[1];
					}
				}
				let token = paramsObj['token'];
				console.log(token, 'token');
				let redirect_url = decodeURIComponent(paramsObj['redirect_url']);

				console.log(redirect_url, 'redirect_url');
				let param = {
					token: token
				}
				callApi.token(param, res => {
					console.log(res, 'tokencall')
					if (res.code == 200) {
						// this.$store.commit('customerloginin', {
						// 	"uid": res.data.uid,
						// 	"nickname": res.data.nickname,
						// 	"unitName": res.data.unitName,
						// 	"unitno": res.data.unitno,
						// 	token: res.data.token,
						// 	usertype:res.data.usertype,
						// 	mchno:res.data.mchno,
						// 	shopno:res.data.shopno
						// })
						// sessionStorage.setItem("token", res.data.token);
						// sessionStorage.setItem("usertype", res.data.usertype);
						// setCookie("login_uid", res.data.uid, 3);
						// setCookie("login_uname", res.data.nickname, 3);
						setloginuser(res.data)
						
						if(redirect_url.indexOf("?") >-1){
							redirect_url = redirect_url + "&token=" + token
						}
						else
						{
							redirect_url = redirect_url + "?token=" + token
						}
						// window.location.href = "http://localhost:8080/#/customer/xcxtemplate/style?tid=1706512645325266945"
						window.location.href = redirect_url 
					}else{
						window.location.href = "/"
					}
				})
			}
		}
	}
</script>

<style scoped>
</style>