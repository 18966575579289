<template>
	<!-- 企微授权回调页面 -->
	<div class="">

		{{authmsg}}
		<div style="color: #ff0000;">
			{{errmsg}}
		</div>
		<el-button @click="funtopage">立即进入</el-button>
	</div>
</template>

<script>
	import callApi from '../../../api/cus/account.js'
	export default {
		data() {
			return {
				authmsg:"",
				errmsg:''
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			
			let auth_code = this.$route.query.auth_code
			console.log(auth_code,'auth_code');
			console.log(mpid,'mpid');
			 this.init()
		},
		methods: {
			init() {
				let url = window.location.href;
				let params = url.split('?')[1];
				let paramsObj = {};
				console.log(params,'params');
				if (params) {
					let paramsArr = params.split('&');
					for (let i = 0; i < paramsArr.length; i++) {
						let param = paramsArr[i].split('=');
						paramsObj[param[0]] = param[1];
					}
				}
				let mpid = paramsObj['id'];
				console.log(mpid, 'mpid');
				this.mpid = mpid
				let auth_code = paramsObj['auth_code'];
				console.log(auth_code, 'auth_code');
				callApi.qywxauthcall(mpid, auth_code, res => {
					console.log(res, 'allauthcall')
					if (res.code == 200) {
						this.authmsg = res.msg
						
						this.$router.push({
							path:'/customer/home/mp/weixinqiye/alls/index?mpid=' + mpid
						})
					}else{
						this.errmsg = res.msg
						this.$message({
							message:res.msg,
							type:'error'
						})
					}
				})
			},
			funtopage(){
				this.$router.push({
					path:'/customer/home/mp/weixinqiye/alls/index?mpid=' + this.mpid
				})
			}
		}
	}
</script>

<style scoped>
</style>