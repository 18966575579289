<template>
	<div>
		<div class="container">
			<div class="markpage">
				<div class="banner" @click.stop="funjoin">
					<img src="../../../assets/image/market/join.png" />
				</div>
				<div class="centerbox">
					<div style="padding: 18px;background-color:#F6F7F9 ;display: flex;align-items: center;border-radius: 10px;">
						<div class="list" >
							<div class="tmpitem" v-for="(item,index) in list" :key="index" >
								<div class="logo"> <img :src="item.logo" /></div>
							</div>
							<div class="designbtn" @click="funitem(item)">立即设计</div>
						</div>
						
					</div>
					<div class="pliohyrax">
						<div class="pliohyrax_left">
							<div class="title">
								今日推荐
							</div>

							<div class="wrap">
								<div class="item" style="margin-right: 22px;" @click="funmarklist">
									<img src="../../../assets/image/market/tj1.png" />
									<div class="tmpmsg">推荐 每日上新</div>
								</div>

								<div class="item" style="margin-right: 22px;" @click="funtempfine">
									<img src="../../../assets/image/market/tj2.png" />
									<div class="tmpmsg2">精品 热门下载</div>
								</div>
								<div class="item"  @click="funtempfine">
									<img src="../../../assets/image/market/tj2.png" />
									<div class="tmpmsg2">精品 热门下载</div>
								</div>
							</div>
						</div>
						<div class="pliohyrax_right">
							<div class="title">
								节日上新
							</div>
							<div class="wrap">
								<div class="left">
									<div class="item" @click="funmarkethot">
										<p>国庆节</p>
										<p>National</p>
									</div>
									<!-- <div class="img" @click="funmarkethot">
										<img src="../../../assets/image/market/icon_market1.png" />
									</div> -->
								</div>
								<div class="right">
									<div class="item qm">
										<p>清明节</p>
										<p>Valentine’s Day</p>

									</div>
									<div class="item ws">
										<p>万圣节</p>
										<p>Valentine’s Day</p>

									</div>
									<div class="item sd">
										<p>圣诞节</p>
										<p>Valentine’s Day</p>

									</div>
									<div class="item yd">
										<p>元旦节</p>
										<p>Valentine’s Day</p>

									</div>
									<div class="item yd">
										<p>元旦节</p>
										<p>Valentine’s Day</p>
									
									</div>
									<div class="item yd">
										<p>元旦节</p>
										<p>Valentine’s Day</p>
									
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="freemail">
					<div class="cls">
						<div class="title">
							免费专区·精美模板限时免费
						</div>
						<div class="cls_wrap">
							<div class="cls_item" :class="currIndex == index ? 'sel' : 'nosel'"
								v-for="(item,index) in hangyehotlist" :key="index" @click="funclsclick(item)">
								<span>{{item.mc}}</span>
							</div>
						</div>
					</div>
					<div class="box">
						<div class="box-wrap">
							<div class="box-item" v-for="(item,index) in listbyfreelist" :key="index"
								>
								<a target="_blank" :href="'/customer/xcxtemplate/marketdetail?tmpid=' + item.tmpid"><img :src="item.logo" /></a>
								
								<div class="box-bg">
									<div class="">
										{{item.tmpname}}
									</div>
								</div>
								<!-- <img class="bgk" src="../../../assets/image/market/bgk.png" /> -->
							</div>
						</div>
					</div>
				</div>
				<div class="freemail">
					<div class="cls">
						<div class="title">
							小程序·高端酒吧必用
						</div>
						<div class="cls_wrap">
							<div class="cls_item" :class="currIndex == index ? 'sel' : 'nosel'"
								v-for="(item,index) in stylehotlist" :key="index" @click="funstyleclsclick(item)">
								<span>{{item.mc}}</span>
							</div>
						</div>
					</div>
					<div class="box">
						<div class="box-wrap">
							<div class="box-item" v-for="(item,index) in listbypaylist" :key="index"
								>
								
								<a target="_blank" :href="'/customer/xcxtemplate/marketdetail?tmpid=' + item.tmpid"><img :src="item.logo" /></a>
								<div class="box-bg">
									<div class="">
										{{item.tmpname}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="color:#000;margin-left: 10px;">
				<router-link to="/system/login">L</router-link>
			</div>
			<showload :showload="showload"></showload>
		</div>
		<pagebottom></pagebottom>
	</div>

</template>

<script>
	import showload from "@/components/loading";
	import marketApi from '../../../api/cus/market.js'
	import util from '../../../utils/util.js'
	import pagebottom from '../../../components/pagebottom.vue'
	
	import designTemplataApi from "../../../api/cus/designtmplate.js";
	export default {
		components: {
			showload,
			pagebottom
			
		},
		data() {
			return {
				searchkey: '',
				showload: false,
				isHoliday: false,
				currIndex: 0,
				form: {
					cls1id: ''
				},
				salelist: [],
				salehotlist: [],
				holidayInfo: {},
				hangyehotlist: [],
				salehotlist: [],
				stylehotlist: [],
				listbypaylist: [],
				listbyfreelist: [],
				islogin: false,
				list:[]
			}
		},
		mounted() {
			 this.init()
			let u = util.getloginuser()
			console.log(u, 'uuuu');
			if (u != null) {
				this.islogin = true
			} else {
				this.islogin = false
			}


		},
		methods: {
			init() {
				document.title = '模版市场-公众号管理平台-大新软件'

				this.funIsHoliday()
				this.gethangyehot()
				this.getsalehot()
				this.getstylehot()
				this.getlistbypaylist()
				this.getlistbyfreelist()
				this.getsalelist()
				this.getmytemplate()
			},
			getsalelist() {
				marketApi.salelist({}, res => {
					console.log(res, 'salelist.res');
					if (res.code == 200) {
						res.data.unshift({
							id: '',
							mc: '全部'
						})
						this.salelist = res.data
					} else if (res.code == 401) {
						delloginall()
						this.$router.push({
							path: '/customer/login'
						})
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			gethangyehot() {
				this.showload = true
				marketApi.hangyehot({}, res => {
					console.log(res, 'hangyehot.res');
					if (res.code == 200) {
						this.hangyehotlist = res.data
					}
				})
			},
			getsalehot() {
				marketApi.salehot({}, res => {
					console.log(res, 'salehot.res');
					if (res.code == 200) {
						this.salehotlist = res.data
					}
				})
			},
			getstylehot() {
				marketApi.stylehot({}, res => {
					console.log(res, 'stylehot.res');
					if (res.code == 200) {
						this.stylehotlist = res.data
					}
				})
			},
			getlistbypaylist() {
				marketApi.listbypay({}, res => {
					console.log(res, 'listbypay.res');
					if (res.code == 200) {
						this.listbypaylist = res.data
					}
				})
			},
			getlistbyfreelist() {
				marketApi.listbyfree({}, res => {
					console.log(res, 'listbyfree.res');
					if (res.code == 200) {
						this.showload = false
						this.listbyfreelist = res.data
					}
				})
			},
			funSaleClick(event) {
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?cls1id=" + this.form.cls1id,
				});
			},
			funitem: function(item) {
				let id = this.list[0].usertmpid
				console.log(item,'item22');
				// this.$router.push({
				// 	path: `/customer/xcxtemplate/list?id=${id}`
				// })
				this.$router.push({
					path: `/customer/xcxtemplate/design?tid=${id}`
					
				})
			},
			funIsHoliday() {
				const currentDate = new Date(); // 获取当前日期
				const month = currentDate.getMonth(); // 月份从0开始计数，所以需要加1
				const day = currentDate.getDay(); // 星期从0到6分别表示周日至周六
				console.log(month, 'month');
				console.log(day, 'day');
				// if (month === 3 && day === 4) { // 判断是否为清明节（四月四号）
				//   this.isHoliday = true;
				// } else if (/* 添加更多的节日判断逻辑 */) {
				//   /* ... */
				// } else {
				//   this.isHoliday = false;
				// }
			},
			funSearch() {
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?searchkey=" + this.searchkey,
				});
			},
			funjoin() {
				this.$router.push({
					path: "/designer/login"
				});
			},
			funmarklist() {
				console.log(11, '11');
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?cls1id=" + '1770732807256776705',
				});

			},
			funtempfine() {
				this.$router.push({
					path: "/customer/xcxtemplate/tempfine?cls1id=" + '1770732807256776705',
				})
			},
			funsaleclsclick(item) {
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?cls1id=" + item.id,
				});
			},
			funclsclick(item, index) {
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?cls3id=" + item.id,
				});
			},
			funstyleclsclick(item, index) {
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?cls2id=" + item.id,
				});
			},
			fundetail(item) {
				this.$router.push({
					path: "/customer/xcxtemplate/marketdetail?tmpid=" + item.tmpid,
				});
			},
			getmytemplate: function() {
				console.log('initdata')
				this.showload = true
				designTemplataApi.getUserTemplate({}, res => {
					console.log(res, 'designTemplataApi.res');
					this.showload = false
					if (res.code == 200) {
						this.list = res.data
					}else if(res.code == 401){
						delloginall()
						this.$router.push({
							path:'/customer/login'
						})
					}else{
						this.$message({
							message:res.msg,
							type:'error'
						})
					}
					console.log(res);
				})
			},
			funmarkethot() {
				return
				this.$router.push({
					path: "/customer/xcxtemplate/markethot",
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../../assets/css/header.min.css';
	@import '../../../assets/css/comm.min.css';
	@import '../../../assets/css/home.min.css';
	@import '../../../assets/css/fkwComm.min.css';

	.container {
		width: 100%;
		height: auto;
	}

	.markpage {
		margin: 0 auto;
		padding-top: 30px;
		width: 1480px;
	}

	.container .search {
		margin: 0 auto;
		width: 874px;
		height: 44px;
		border-radius: 12px;
		border: 1px solid #3388FF;
		background-color: #fff;
	}

	.container .search .search-wrap {
		display: flex;
	}

	.container .search .search-wrap .search_select {
		width: 95px;
		height: 100%;
		position: relative;
	}

	.container .search .search-wrap .search_select::after {
		content: '';
		display: block;
		height: 100%;
		width: 1px;
		background-color: #D4D4D4;
		position: absolute;
		right: 0;
		top: 0;
	}

	.container .search .search-wrap .search_select .selstyle {
		color: #272727;
		outline: none;
		border: none;
		background: none;
		padding: 12px 15px;
	}

	.container .search .search-wrap .search_input {
		width: 100%;
	}

	.container .search .search-wrap .search_input input {
		padding-left: 10px;
		width: 700px;
		height: 42px;
		outline: none;
		border: none;
		background: none;
	}

	.container .search .search-wrap .search_click {
		cursor: pointer;
		width: 80px;
		height: 44px;
		background: #3388FF;
		border-radius: 0px 11px 11px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.container .search .search-wrap .search_click img {
		width: 25px;
		height: 25px;
	}

	.container .banner {
		margin-top: 38px;
		width: 100%;
		height: 264px;
	}

	.container .banner img {
		width: 100%;
		height: 100%;
	}

	.container .menu {
		margin-top: 38px;
	}

	.container .menu .menu-wrap {
		display: flex;
		justify-content: flex-start;
	}

	.container .menu .menu-wrap .menu-item {
		margin-left: 10px;
		cursor: pointer;
		color: #fff;
		width: 162px;
		height: 80px;
		border-radius: 10px;
		padding: 10px 0 0 10px;
		display: flex;
		flex-direction: column;
		background-color: #3388FF;
	}

	.container .menu .menu-wrap .menu-item .title {
		font-weight: 800;
		font-size: 20px;
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 500;

	}

	.container .menu .menu-wrap .menu-item .memo {
		margin-top: 20px;
		font-weight: 800;
		font-size: 18px;
		font-family: Source Han Sans CN, Source Han Sans CN;
	}

	.container .menu .menu-wrap .menu-item:nth-child(2) {
		background-color: #FF64D4;
	}

	.container .menu .menu-wrap .menu-item:nth-child(3) {
		background-color: #1FDE60;
	}

	.container .menu .menu-wrap .menu-item:nth-child(4) {
		background-color: #FD5660;
	}

	.container .menu .menu-wrap .menu-item:nth-child(5) {
		background-color: #3388FF;
		color: #ffffff;
	}

	.container .menu .menu-wrap .menu-item:nth-child(5) .title {}

	.container .menu .menu-wrap .menu-item:nth-child(5) .memo {}

	.container .menu .menu-wrap .menu-item:nth-child(6) {
		background-color: #FF64D4;
		color: #ffffff;
	}

	.container .menu .menu-wrap .menu-item:nth-child(6) .title {}

	.container .menu .menu-wrap .menu-item:nth-child(6) .memo {}

	.container .menu .menu-wrap .menu-item:nth-child(7) {
		background-color: #1FDE60;
		color: #ffffff;
	}

	.container .menu .menu-wrap .menu-item:nth-child(7) .title {}

	.container .menu .menu-wrap .menu-item:nth-child(7) .memo {}

	.container .menu .menu-wrap .menu-item:nth-child(8) {
		background-color: #FD5660;
		color: #ffffff;
	}

	.container .menu .menu-wrap .menu-item:nth-child(8) .title {}

	.container .menu .menu-wrap .menu-item:nth-child(8) .memo {}

	.container .pliohyrax {
		margin-left: 32px;
		display: flex;
		flex-direction: column;
		padding: 18px 32px 32px 32px;
		background-color: #F6F7F9;
		border-radius: 10px;
	}

	.container .pliohyrax .pliohyrax_left {
		width: 50%;
		margin-right: 10px;
	}

	.container .pliohyrax .pliohyrax_left .wrap {
		display: flex;
		justify-content: space-between;


	}
	.container .pliohyrax .pliohyrax_left .wrap .item{
		flex-shrink: 0;
	}
	.container .pliohyrax .pliohyrax_left .wrap .item:hover {
		cursor: pointer;
		transition: all .7s;
		transform: scale(1.1);
	}

	.container .pliohyrax .pliohyrax_left .wrap .item:nth-child(1) {
		width: 476px;
		height: 206px;
		background-color: #E3E8E2;
		position: relative;

	}

	.container .pliohyrax .pliohyrax_left .wrap .item:nth-child(1) img {
		width: 100%;
		height: 100%;

	}

	.container .pliohyrax .pliohyrax_left .wrap .item:nth-child(2) {
		width: 228px;
		height: 206px;
		background-color: #DAF1E3;
		position: relative;
	}

	.container .pliohyrax .pliohyrax_left .wrap .item:nth-child(2) img {
		width: 100%;
		height: 100%;

	}

	.tmpmsg {
		background-color: #EDEFF0;
		padding-left: 10px;
		line-height: 30px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.tmpmsg2 {
		background-color: #EDEFF0;
		padding-left: 10px;
		line-height: 30px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.container .pliohyrax .pliohyrax_left .title,
	.container .pliohyrax .pliohyrax_right .title {
		font-weight: 800;
		font-size: 18px;
		color: #000000;
		margin-bottom: 10px;
	}

	.container .pliohyrax .pliohyrax_right {
		margin-left: 10px;
		
		position: relative;
	}

	.container .pliohyrax_right .wrap {
		display: flex;
		justify-content: space-between;
	}

	.container .pliohyrax_right .left {
		margin-right: 20px;
		position: relative;
		width: 228px;
		height: 206px;
	}

	.container .pliohyrax_right .left .item {
		width: 228px;
		height: 206px;
		background: url("../../../assets/image/market/icon_market1.png");
		color: #ffffff;
	}

	.container .pliohyrax_right .right {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.container .pliohyrax_right .right .item {
		width: 226px;
		height: 92px;
		color: #000000;
		margin-right: 24px;
	}

	.container .pliohyrax_right .left .item p {

		color: #000000;
	}

	.container .pliohyrax_right .left .item p:nth-child(1) {
		font-weight: 800;
		font-size: 22px;
		padding: 20px 0 0 20px;
	}

	.container .pliohyrax_right .left .item p:nth-child(2) {
		font-weight: 400;
		font-size: 16px;
		padding: 10px 0 0 20px;
	}

	.container .pliohyrax_right .right .item p {
		padding: 10px 0 0 10px;

	}

	.container .pliohyrax_right .right .item p:nth-child(1) {
		font-weight: 800;
		font-size: 22px;
	}

	.container .pliohyrax_right .right .item p:nth-child(2) {
		font-weight: 400;
		font-size: 16px;
	}

	.qm {
		background: url('../../../assets/image/market/icon_market5.png') no-repeat;
	}

	.ws {
		background: url('../../../assets/image/market/icon_market4.png') no-repeat;
	}

	.sd {
		background: url('../../../assets/image/market/icon_market2.png') no-repeat;
		

	}

	.yd {
		background: url('../../../assets/image/market/icon_market3.png') no-repeat;
		margin-top: 22px;

	}

	.container .pliohyrax_right .left .item .img,
	.container .pliohyrax_right .right .item .img {}

	.container .pliohyrax_right .right .item .img {
		width: 80px;
		height: 80px;
	}

	.container .pliohyrax_right .right .item .img img {
		width: 100%;
		height: 100%;
	}

	.container .freemail {
		margin-top: 50px;
	}

	.container .freemail .cls {
		display: flex;
	}

	.container .freemail .title {
		font-weight: 800;
		font-size: 18px;
		color: #000000;
	}

	.container .freemail .cls .cls_wrap {
		margin-left: 30px;
		display: flex;
	}

	.container .freemail .cls .cls_wrap .cls_item {
		cursor: pointer;
		margin-left: 10px;
		padding: 0 10px;
		width: 64px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		background: #F3F4F7;
		border-radius: 20px 20px 20px 20px;
	}

	.container .freemail .cls .cls_wrap .cls_item span {
		font-size: 12px;
	}

	.container .freemail .cls .cls_wrap .sel {
		color: #3388FF;
	}

	.container .freemail .cls .cls_wrap .nosel {
		color: #383838;
	}

	.container .freemail .box {
		margin-top: 20px;
	}

	.container .freemail .box .box-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.container .freemail .box .box-wrap .box-item {
		margin-left: 10px;
		margin-bottom: 10px;
		cursor: pointer;
		width: 230px;
		height: 358px;
		background: #F6F7F9;
		border-radius: 10px 10px 10px 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	.container .freemail .box .box-wrap .box-item img {
		width: 150px;
		height: 305px;
	}

	.container .freemail .box .box-wrap .box-item .bgk {
		position: absolute;
		left: 0;
		top: 0;
	}

	.container .freemail .box .box-wrap .box-item .box-bg {
		overflow: hidden;
		opacity: 0;
		transition: all .3s;
	}

	.container .freemail .box .box-wrap .box-item:hover .box-bg {
		opacity: 1;
		width: 100%;
		height: 45px;
		text-align: center;
		background-color: #fff;
		box-shadow: 0 6px 14px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%);
	}


	.container p {
		margin: 0 !important;
	}

	.centerbox {
		display: flex;
		margin-top: 53px;
	}

	.h100 {
		width: 100%;
		height: 100px;
	}
	.list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom:20px;
	}
	.designbtn{
		width: 88px;
		height: 32px;
		background: #FFFFFF;
		border-radius: 2px 2px 2px 2px;
		border: 1px solid #3388FF;
		color: #3388FF;
		text-align: center;
		line-height: 32px;
		margin: 24px auto;
		cursor: pointer;
	}
	.tmpitem {
		text-align: center;
		box-shadow: 0 2px 6px #ccc;
		padding: 20px;
		margin: 20px 20px 0 0;
		position: relative;
	
		&:hover {
			box-shadow: 0 2px 6px #2865e0;
			border-radius: 20px;
		}
	
		.logo {
			width: 200px;
			height: 350px;
			overflow: hidden;
	
			img {
				width: 100%;
			}
		}
	}
</style>